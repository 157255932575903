import React from "react";
import theme from "theme";
import { Theme, Text, Box, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"404"} />
		<Helmet>
			<title>
				SPhotonix — Revolutionizing Data Storage & Photonics
			</title>
			<meta name={"description"} content={"Discover SPhotonix’s cutting-edge data storage solutions. We specialize in 5D optical storage technology, pushing the boundaries of durability, scalability, and future-proof innovation for enterprise storage systems. Partner with us to bring next-generation data storage to market."} />
			<meta property={"og:title"} content={"SPhotonix — Revolutionizing Data Storage & Photonics"} />
			<meta property={"og:description"} content={"SPhotonix pioneers the future of data storage with breakthrough 5D optical technology. Our innovative solutions offer durable, scalable, and ultra-secure storage for enterprises looking to safeguard their data for decades. Explore how we are revolutionizing the industry."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-new-logo-white.svg?v=2024-09-17T17:56:51.435Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav32w.png?v=2024-09-23T14:28:13.820Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav152w.png?v=2024-09-23T14:28:06.690Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/SPhotonix-fav270w.png?v=2024-09-23T14:27:58.132Z"} />
			<meta name={"msapplication-TileColor"} content={"#ffffff"} />
		</Helmet>
		<Components.Header />
		<Box min-width="100px" min-height="100px" padding="240px 190px 240px 190px" md-padding="120px 16px 120px 16px">
			<Box
				min-width="100px"
				min-height="100px"
				align-items="center"
				display="flex"
				flex-direction="column"
				justify-content="center"
				flex-wrap="no-wrap"
				grid-row-gap="48px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					flex-wrap="no-wrap"
					align-items="center"
					justify-content="center"
					grid-row-gap="16px"
				>
					<Text
						margin="0 0 0 0"
						text-align="center"
						word-wrap="break-word"
						text-fill-color="transparent"
						style={{
							"-webkit-text-fill-color": "transparent"
						}}
						background="linear-gradient(91.33deg, #080808 34.46%, #830A12 50.92%, #FF0C1D 67.34%), #080808"
						background-clip="text"
						font="--headingH0"
					>
						404
					</Text>
				</Box>
				<Button
					background="--color-accentRed"
					border-radius="8px"
					justify-content="flex-start"
					align-items="flex-start"
					display="flex"
					color="white"
					font="--bodyText17SemiBold"
					word-wrap="break-word"
					focus-box-shadow="none"
					padding-left="24px"
					padding-right="24px"
					padding-top="16px"
					padding-bottom="16px"
					position="relative"
					sm-width="100%"
					sm-justify-content="center"
					padding="16px 24px 16px 24px"
				>
					Back to home page
				</Button>
			</Box>
		</Box>
		<Box
			width="100%"
			padding-top="100px"
			padding-bottom="24px"
			padding-left="190px"
			padding-right="190px"
			flex-direction="column"
			justify-content="flex-start"
			align-items="flex-start"
			grid-gap="140px"
			display="flex"
			sm-display="flex"
			sm-padding="24px 16px 24px 16px"
			sm-height="min-content"
			sm-grid-row-gap="64px"
			quarkly-title="Footer"
			grid-row-gap="0px"
			grid-column-gap="0px"
			padding="0px 190px 24px 190px"
			md-padding="0px 16px 24px 16px"
		>
			<Box
				align-self="stretch"
				height="51px"
				flex-direction="column"
				justify-content="flex-start"
				align-items="flex-start"
				grid-gap="24px"
				display="flex"
				sm-display="flex"
				sm-grid-row-gap="32px"
				sm-height="auto"
			>
				<Box align-self="stretch" height="1px" background="#B1B1B1" width="100%" />
				<Components.Footer />
			</Box>
		</Box>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66b79a6ce778710018d27b83"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}\n\n\nbody  {\nzoom: 0.85;\n}\n"}
			</style>
			<script async={true} src={"https://www.googletagmanager.com/gtag/js?id=G-GFK1N3P5RL"} place={"endOfBody"} rawKey={"670146df6aba74b20ebdd83f"} />
			<script place={"endOfBody"} rawKey={"670147228b237590430d8fce"}>
				{"  window.dataLayer = window.dataLayer || [];\n  function gtag(){dataLayer.push(arguments);}\n  gtag('js', new Date());\n\n  gtag('config', 'G-GFK1N3P5RL');"}
			</script>
			<link
				href={"https://uploads.quarkly.io/66b79a6ce778710018d27b85/images/sphotonix-favicon.ico?v=2025-03-25T15:31:48.817Z"}
				type={"image/x-icon"}
				rel={"icon"}
				place={"endOfHead"}
				rawKey={"67e2cb378695622ce9816272"}
			/>
		</RawHtml>
	</Theme>;
});